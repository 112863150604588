import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBath } from '@fortawesome/free-solid-svg-icons'
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const room1 = {
    carouselImages : importAll(require.context('../../../images/rooms/undertheeaves/', false, /\.(png|jpe?g|svg)$/)),
    roomName : 'Under the Eaves',
    roomImagesName : 'undertheeaves',
    roomDescription : 'UNDERTHEEAVES',
    roomBed : 'King/Twin',
    roomDescCouch: 'COUCH',
    smoobuCode:'1551304', 
    smoobuDataVerification: '1b6369dde1fcd9ebf750eb581aa40397cdd591cc966b9676c7d8ddf6839a2c92',
    smoobuId: 'smoobuApartment1551304en'

  }

const room2 = {
  carouselImages : importAll(require.context('../../../images/rooms/bluelagoon/', false, /\.(png|jpe?g|svg)$/)),
  roomName : 'Blue Lagoon',
  roomImagesName : 'bluelagoon',
  roomDescription : 'THEBLUELAGOON',
  roomBed : 'King',
  roomDescCouch: 'BALCON',
  smoobuCode:'1551307', 
  smoobuDataVerification: '9148a4e7e4ba35f5d425a64cf79448b65943291766feb1caf383d0e9051bd598',
  smoobuId: 'smoobuApartment1551307en'
}

const room3 = {
    carouselImages : importAll(require.context('../../../images/rooms/redroofs/', false, /\.(png|jpe?g|svg)$/)),
    roomName : 'Red Roofs',
    roomImagesName: 'redroofs',
    roomDescription : 'REDROOFS',
    roomBed : 'King/Twin',
    roomDescCouch: 'COUCH',
    smoobuCode:'1551310', 
    smoobuDataVerification: 'd179ccf958a8b61266b45aaff792338fea21b5db1cef4bb64f09f1c2db394c82',
    smoobuId: 'smoobuApartment1551310en'
  }

  const room4 = {
    carouselImages :  importAll(require.context('../../../images/rooms/indiansummer/', false, /\.(png|jpe?g|svg)$/)),
    roomName : 'Indian Summer',
    roomImagesName: 'indiansummer',
    roomDescription : 'INDIANSUMMER',
    roomBed : 'King',
    roomSymbolBath: <FontAwesomeIcon icon={faBath} />,
    roomDescBath: 'BATH',
    roomDescCouch: 'COUCH',
    smoobuCode:'1551313', 
    smoobuDataVerification: 'fcf42bd018e64700fdf8df1ea2f1c7be689e8846595b4299718d312d7692e539',
    smoobuId: 'smoobuApartment1551313en'
  }

const rooms = [
    ['room1', room1],
    ['room2', room2],
    ['room3', room3],
    ['room4', room4]
];
export const roomMap = new Map(rooms);