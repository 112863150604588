import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from 'react-i18next';
import imglogo from '../images/23-Logo-rgb-72ppi1.png';
import cookies from 'js-cookie'
import 'flag-icon-css/css/flag-icons.css'
import i18next from 'i18next'

import './Header.css';

let currentSelectedLanguageCode ="en-US";


const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'de',
    name: 'Deutsch',
    country_code: 'de',
  }
]




function transformLang(currentLanguageCode){
  switch (currentLanguageCode) {
    case 'en':
      return 'soulac23-en';
    case 'fr':
      return 'soulac23-fr';
    case 'de':
      return 'soulac23-de';
    default:
      return 'en-US';
  }

}

function updateLang(currentLanguageCode){
  currentSelectedLanguageCode = transformLang(currentLanguageCode);
  if (typeof window.axeptioSDK !== 'undefined') {
    try {
      window.axeptioSDK.setCookiesVersion(currentSelectedLanguageCode);
    } catch (error) {
    console.error('Error executing the command:', error);
    }
  }
}

function Header() {

  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()
  updateLang(currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir
    document.title = t('APP_TITLE')


  }, [currentLanguage, t])


  return (
    <>
     <Navbar expand="lg">
      <Container className="container-fluid">
        <Navbar.Brand href="/">
        <img
              src={imglogo}
              width="60"
              height="50"
              className="d-inline-block align-top"
              alt="Soulac Logo"
            />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          
        <Navbar.Collapse id="basic-navbar-nav" >
          <Nav className="ms-auto">
            <Nav.Link href="aboutus">{t('ABOUTUS')}</Nav.Link>
            <NavDropdown title={t('OURHOUSE')} id="basic-nav-dropdown-house">
            <NavDropdown.Item href="ourhouse">{t('OURHOUSE')} </NavDropdown.Item>
                <NavDropdown.Item href="terms">  {t('TERMSOFSERVICE')}</NavDropdown.Item>
                <NavDropdown.Item href="breakfast">  {t('BREAKFAST')}</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="rooms">{t('ROOMS')}</Nav.Link>
            <Nav.Link className="light" href="region">{t('REGION1')}</Nav.Link>
            <Nav.Link className="light" href="enviroment">{t('LOCATION')}</Nav.Link>
            <Nav.Link className="light" href="gallery">{t('GALLERY')}</Nav.Link>
            <Nav.Link className="light" href="booking">{t('BOOKING')}</Nav.Link>
            <Nav.Link className="light" href="contact">{t('CONTACT')}</Nav.Link>
                <Navbar.Text>
                {(() => {
                  let buttons = [];
                  languages.filter(({ code, name, country_code }) => code !== currentLanguageCode).map(({ code, name, country_code }) => (
                    buttons.push(<span key={code} onClick={() => {
                        i18next.changeLanguage(code);
                        
                        updateLang(code);
                        
                      }} className={`flag-icon flag-icon-${country_code} marginRight`}
                        ></span>
            
                  )
                  ))
                return buttons;
             
              })()}
               </Navbar.Text>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    
    </>
  );
}

export default Header;


