import React from 'react';
import '../../App.css';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';

export default function Contact() {
    const { t } = useTranslation();



    return (
        <><Container>
            <h1>{t('CONTACT')}</h1>
            <Container>

                <b>{t('EMAIL')}:</b>  info@soulac23.com<br></br>
                <b> {t('TELEPHONE')}:</b>  +33 6 61 99 95 68  <br></br><br></br>
                <b> {t('ADRESS')}:</b>  23 Rue du Cardinal Donnet, 33780 Soulac-sur-Mer, {t('FRANCE')}  <br></br><br></br>
          
                </Container>


                </Container></>

    )
}
