import '../../App.css';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';


export default function Region() {
    const { t } = useTranslation();

  


    return (
        <><Container>
           
            <h1>{t('REGION1')}</h1>
            <Container>
                

                {t('REGION2')} <br></br><br></br>
                {t('REGION3')} <br></br><br></br>
                {t('REGION4')} <br></br><br></br>
                {t('REGION5')} <br></br><br></br>
                {t('REGION6')} <br></br><br></br>
                {t('REGION7')} <br></br><br></br>
                {t('REGION8')} <br></br><br></br>
                {t('REGION9')} <br></br><br></br>
                             <a href="https://www.medoc-bordeaux.com/">https://www.medoc-bordeaux.com/</a><br></br><br></br>
                <a href="https://www.mairie-soulac.fr/">https://www.mairie-soulac.fr/</a><br></br><br></br>
                <a href="http://medoc-tourisme.com/fr/">http://medoc-tourisme.com/fr/</a><br></br><br></br>
                <a href="https://www.medoc-atlantique.com/fiches/office-de-tourisme-de-soulac-sur-mer/">https://www.medoc-atlantique.com/fiches/office-de-tourisme-de-soulac-sur-mer/</a>
                <br></br><br></br>
            </Container>


          
        </Container></>
    )
}
