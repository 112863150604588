import '../../App.css';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import img1 from '../../images/ourhouse/house_12_420.png';
import img2 from '../../images/ourhouse/house_12_980.png';
import img3 from '../../images/ourhouse/house_12_1680.png';
import img4 from '../../images/ourhouse/house_1_420.png';
import img5 from '../../images/ourhouse/house_1_980.png';
import img6 from '../../images/ourhouse/house_1_1680.png';
import img7 from '../../images/ourhouse/house_2_420.png';
import img8 from '../../images/ourhouse/house_2_980.png';
import img9 from '../../images/ourhouse/house_2_1680.png';
import img10 from '../../images/ourhouse/house_3_420.png';
import img11 from '../../images/ourhouse/house_3_980.png';
import img12 from '../../images/ourhouse/house_3_1680.png';
import img13 from '../../images/ourhouse/house_4_420.png';
import img14 from '../../images/ourhouse/house_4_980.png';
import img15 from '../../images/ourhouse/house_4_1680.png';
import img16 from '../../images/ourhouse/house_5_420.png';
import img17 from '../../images/ourhouse/house_5_980.png';
import img18 from '../../images/ourhouse/house_5_1680.png';
import img19 from '../../images/ourhouse/house_6_420.png';
import img20 from '../../images/ourhouse/house_6_980.png';
import img21 from '../../images/ourhouse/house_6_1680.png';
import img22 from '../../images/ourhouse/house_7_420.png';
import img23 from '../../images/ourhouse/house_7_980.png';
import img24 from '../../images/ourhouse/house_7_1680.png';
import img25 from '../../images/ourhouse/house_8_420.png';
import img26 from '../../images/ourhouse/house_8_980.png';
import img27 from '../../images/ourhouse/house_8_1680.png';
import img28 from '../../images/ourhouse/house_9_420.png';
import img29 from '../../images/ourhouse/house_9_980.png';
import img30 from '../../images/ourhouse/house_9_1680.png';
import img31 from '../../images/ourhouse/house_10_420.png';
import img32 from '../../images/ourhouse/house_10_980.png';
import img33 from '../../images/ourhouse/house_10_1680.png';
import img40 from '../../images/ourhouse/house_13_420.png';
import img41 from '../../images/ourhouse/house_13_980.png';
import img42 from '../../images/ourhouse/house_13_1680.png';
import img43 from '../../images/ourhouse/house_14_420.png';
import img44 from '../../images/ourhouse/house_14_980.png';
import img45 from '../../images/ourhouse/house_14_1680.png';
import img46 from '../../images/ourhouse/house_15_420.png';
import img47 from '../../images/ourhouse/house_15_980.png';
import img48 from '../../images/ourhouse/house_15_1680.png';
import img49 from '../../images/ourhouse/house_16_420.png';
import img50 from '../../images/ourhouse/house_16_980.png';
import img51 from '../../images/ourhouse/house_16_1680.png';
import img52 from '../../images/ourhouse/house_17_420.png';
import img53 from '../../images/ourhouse/house_17_980.png';
import img54 from '../../images/ourhouse/house_17_1680.png';
import img55 from '../../images/ourhouse/house_18_420.png';
import img56 from '../../images/ourhouse/house_18_980.png';
import img57 from '../../images/ourhouse/house_18_1680.png';
import img58 from '../../images/ourhouse/house_19_420.png';
import img59 from '../../images/ourhouse/house_19_980.png';
import img60 from '../../images/ourhouse/house_19_1680.png';
import img61 from '../../images/ourhouse/house_20_420.png';
import img62 from '../../images/ourhouse/house_20_980.png';
import img63 from '../../images/ourhouse/house_20_1680.png';
import img64 from '../../images/ourhouse/house_21_420.png';
import img65 from '../../images/ourhouse/house_21_980.png';
import img66 from '../../images/ourhouse/house_21_1680.png';

import Carousel from 'react-bootstrap/Carousel';

export default function OurHouse() {
    const { t } = useTranslation();

    let srcSet = img1 + ' 420w, '
        + img2 + ' 980w, '
        + img3 + ' 1680w';

        let srcSet2 = img4 + ' 420w, '
        + img5 + ' 980w, '
        + img6 + ' 1680w';

        let srcSet3 = img7 + ' 420w, '
        + img8 + ' 980w, '
        + img9 + ' 1680w';

        let srcSet4 = img10 + ' 420w, '
        + img11 + ' 980w, '
        + img12 + ' 1680w';

        let srcSet5 = img13 + ' 420w, '
        + img14 + ' 980w, '
        + img15 + ' 1680w';

        let srcSet6 = img16 + ' 420w, '
        + img17 + ' 980w, '
        + img18 + ' 1680w';
        let srcSet7 = img19 + ' 420w, '
        + img20 + ' 980w, '
        + img21 + ' 1680w';
        let srcSet8 = img22 + ' 420w, '
        + img23 + ' 980w, '
        + img24 + ' 1680w';
        let srcSet9 = img25 + ' 420w, '
        + img26 + ' 980w, '
        + img27 + ' 1680w';
        let srcSet10 = img28 + ' 420w, '
        + img29 + ' 980w, '
        + img30 + ' 1680w';
        let srcSet11 = img31 + ' 420w, '
        + img32 + ' 980w, '
        + img33 + ' 1680w';
        let srcSet14 = img40 + ' 420w, '
        + img41 + ' 980w, '
        + img42 + ' 1680w';
        let srcSet15 = img43 + ' 420w, '
        + img44 + ' 980w, '
        + img45 + ' 1680w';
        let srcSet16 = img46 + ' 420w, '
        + img47 + ' 980w, '
        + img48 + ' 1680w';
        let srcSet17 = img49 + ' 420w, '
        + img50 + ' 980w, '
        + img51 + ' 1680w';
        let srcSet18 = img52 + ' 420w, '
        + img53 + ' 980w, '
        + img54 + ' 1680w';
        let srcSet19 = img55 + ' 420w, '
        + img56 + ' 980w, '
        + img57 + ' 1680w'
        let srcSet20 = img58 + ' 420w, '
        + img59 + ' 980w, '
        + img60 + ' 1680w';
        let srcSet21 = img61 + ' 420w, '
        + img62 + ' 980w, '
        + img63 + ' 1680w';
        let srcSet22 = img64 + ' 420w, '
        + img65 + ' 980w, '
        + img66 + ' 1680w'

    return (
        <><Container>
            <h1>{t('OURHOUSE')}</h1>
            <Row>

                {t('OURHOUSE1')} <br></br><br></br>
                {t('OURHOUSE2')} <br></br><br></br>
                {t('OURHOUSE3')} <br></br><br></br>
                {t('OURHOUSE4')} <br></br><br></br>
                {t('OURHOUSE5')} <br></br><br></br>
                {t('OURHOUSE6')} <br></br><br></br>

            </Row>

            <Container>


                <Carousel>
                <Carousel.Item interval={2000}>
                        <img
                            className="d-block w-100"
                            src={img60}
                            sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                            srcSet={srcSet20}
                            alt="The hosts" />
                    </Carousel.Item>
                <Carousel.Item interval={2000}>
                        <img
                            className="d-block w-100"
                            src={img63}
                            sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                            srcSet={srcSet21}
                            alt="The hosts" />
                    </Carousel.Item>
                <Carousel.Item interval={2000}>
                        <img
                            className="d-block w-100"
                            src={img66}
                            sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                            srcSet={srcSet22}
                            alt="The hosts" />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                        <img
                            className="d-block w-100"
                            src={img3}
                            sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                            srcSet={srcSet}
                            alt="The hosts" />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                        <img
                            className="d-block w-100"
                            src={img42}
                            sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                            srcSet={srcSet14}
                            alt="The hosts" />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                        <img
                            className="d-block w-100"
                            src={img45}
                            sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                            srcSet={srcSet15}
                            alt="The hosts" />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                        <img
                            className="d-block w-100"
                            src={img48}
                            sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                            srcSet={srcSet16}
                            alt="The hosts" />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                        <img
                            className="d-block w-100"
                            src={img51}
                            sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                            srcSet={srcSet17}
                            alt="The hosts" />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                        <img
                            className="d-block w-100"
                            src={img54}
                            sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                            srcSet={srcSet18}
                            alt="The hosts" />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                        <img
                            className="d-block w-100"
                            src={img57}
                            sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                            srcSet={srcSet19}
                            alt="The hosts" />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                        <img
                            className="d-block w-100"
                            src={img6}
                            sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                            srcSet={srcSet2}
                            alt="The hosts" />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                        <img
                            className="d-block w-100"
                            src={img9}
                            sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                            srcSet={srcSet3}
                            alt="The hosts" />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                        <img
                            className="d-block w-100"
                            src={img12}
                            sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                            srcSet={srcSet4}
                            alt="The hosts" />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                        <img
                            className="d-block w-100"
                            src={img15}
                            sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                            srcSet={srcSet5}
                            alt="The hosts" />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                        <img
                            className="d-block w-100"
                            src={img18}
                            sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                            srcSet={srcSet6}
                            alt="The hosts" />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                        <img
                            className="d-block w-100"
                            src={img21}
                            sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                            srcSet={srcSet7}
                            alt="The hosts" />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                        <img
                            className="d-block w-100"
                            src={img24}
                            sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                            srcSet={srcSet8}
                            alt="The hosts" />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                        <img
                            className="d-block w-100"
                            src={img27}
                            sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                            srcSet={srcSet9}
                            alt="The hosts" />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                        <img
                            className="d-block w-100"
                            src={img30}
                            sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                            srcSet={srcSet10}
                            alt="The hosts" />
                    </Carousel.Item>    <Carousel.Item interval={2000}>
                        <img
                            className="d-block w-100"
                            src={img33}
                            sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                            srcSet={srcSet11}
                            alt="The hosts" />
                    </Carousel.Item>
                </Carousel>
            </Container>


        </Container></>
    )
}
