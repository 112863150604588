import '../../App.css';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';



function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }

const galleryimgs = {
carouselImages : importAll(require.context('../../images/gallery/', false, /\.(png|jpe?g|svg)$/)),
roomImagesName : 'Gallery'
}

export default function Gallery() {
    const { t } = useTranslation();

    

    return (
        <><Container>
            <h1>{t('GALLERY')}</h1>

            <Carousel>
            {(() => {
              let items = [];
              for (let i = 0; i < Object.keys(galleryimgs.carouselImages).length / 3; i++) {
                let imageId = i + 1;
                let imageName = galleryimgs.roomImagesName + '_' + imageId + '_1680.png';
                let srcSet = galleryimgs.carouselImages[galleryimgs.roomImagesName + '_' + imageId + '_420.png'] + ' 420w, '
                  + galleryimgs.carouselImages[galleryimgs.roomImagesName + '_' + imageId + '_980.png'] + ' 980w, '
                  + galleryimgs.carouselImages[galleryimgs.roomImagesName + '_' + imageId + '_1680.png'] + ' 1680w';
                items.push(
                  <Carousel.Item key={"room-img-" + i} interval={2000}>
                    <img
                      className="d-block w-100"
                      src={galleryimgs.carouselImages[imageName]}
                      sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                      srcSet={srcSet}
                      alt="breathtaking room" />
                  </Carousel.Item>

                );
              }
              return items;
            })()}
          </Carousel>

           

        </Container></>
    )
}
