import '../../App.css';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
export default function Faciltity() {
    const { t } = useTranslation();



    return (
        <><Container>
            <h1>{t('HOUSEFACILITIES')}</h1>
            <Container>

                {t('FACILTITY1')} <br></br><br></br>
                {t('FACILTITY2')} <br></br><br></br>
                {t('FACILTITY3')} <br></br><br></br>
                {t('FACILTITY4')} <br></br><br></br>
                {t('FACILTITY5')} <br></br><br></br>
                {t('FACILTITY6')} <br></br><br></br>
          
            </Container>

        </Container></>
    )
}
