import React from 'react';
import '../../App.css';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';

export default function Terms() {
    const { t } = useTranslation();



    return (
        <><Container>
            <h1>{t('TERMSOFSERVICE')}</h1>
            <Container>

                <b>{t('TERMS1')}</b> <br></br>
                {t('TERMS2')}  <br></br><br></br>
                <b>{t('TERMS3')}</b>  <br></br> 
                {t('TERMS4')}  <br></br><br></br>
                <b>{t('TERMS5')}</b>  <br></br>
                {t('TERMS6')} <br></br>
                {t('TERMS7')} <br></br>
                {t('TERMS8')} <br></br><br></br>
                {t('TERMS9')} <br></br>
                {t('TERMS10')} <br></br><br></br>
                {t('TERMS11')} <br></br><br></br>
                <b>{t('TERMS12')}</b>  <br></br>
                {t('TERMS13')} <br></br>
                {t('TERMS14')} <br></br>
                {t('TERMS15')} <br></br><br></br>
                {t('TERMS16')} <br></br>
          
                </Container>


                </Container></>

    )
}
