import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import imgroom1 from '..//images/rooms/undertheeaves/undertheeaves_2_980.png';
import imgroom2 from '..//images/rooms/bluelagoon/bluelagoon_1_980.png';
import imgroom4 from '..//images/rooms/redroofs/redroofs_2_980.png';
import imgroom3 from '..//images/rooms/indiansummer/indiansummer_1_980.png';
import { useTranslation } from 'react-i18next';

function CardsRoom() {
  const { t } = useTranslation()
  return (
    <><div className='cards'>
      <h1>{t('CHECKOUTROOMS')}</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={imgroom1}
              text= {t('UNDERTHEEAVES')}
              label='Under The Eaves'
              path='/room1' />
            <CardItem
              src={imgroom2}
              text={t('THEBLUELAGOON')}
              label='The Blue Lagoon'
              path='/room2' />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={imgroom4}
              text={t('REDROOFS')}
              label='Red Roofs'
              path='/room3' />
            <CardItem
              src={imgroom3}
              text= {t('INDIANSUMMER')}
              label='Indian Summer'
              path='/room4' />
          </ul>
        </div>
      </div>
    </div></>
  );
}

export default CardsRoom;