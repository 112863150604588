import React, { useEffect } from 'react';
import '../../App.css';
import { useTranslation } from 'react-i18next';


export default function Booking() {
  const { t } = useTranslation();

  let initialized = false;
  const initializeBookingScript = () => {
    const script = document.createElement("script");
    script.src = "https://login.smoobu.com/js/Settings/BookingToolIframe.js";
    script.async = true;
    script.onload = () => scriptLoaded();
    document.body.appendChild(script);
  }
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      initializeBookingScript();
    }
  }, []);
  const scriptLoaded = () => {
    window.BookingToolIframe.initialize({ "url": "https://login.smoobu.com/de/booking-tool/iframe/542164", "baseUrl": "https://login.smoobu.com", "target": "#apartmentIframeAll" });
  }

  return (
    <>
      <div className='container'>
        <h1>{t('BOOKINGTEXT')}</h1>
       
      
      <div id='apartmentIframeAll'></div>
      </div>
    </>
  )
}
