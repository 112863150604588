import React from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import Container from 'react-bootstrap/Container';

const containerStyle = {
  
  width: "(max-width: 420px) 80vw, (max-width:1100px) 60vw, 30vw",
  height: "50vw"
};



const center = {
  lat: 45.51186990369182,
  lng: -1.125402766046941
}; 

function MyComponent() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBGnHcjjj5S8rTLH1MAbGnJpa-YGiUelbI"
  })

  const [setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    //const bounds = new window.google.maps.LatLngBounds(center);
    //map.fitBounds(bounds);

    //setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
      <Container>
       <GoogleMap 
        mapContainerStyle={containerStyle}
        center={center}
        zoom={7}
        onLoad={onLoad}
        onUnmount={onUnmount}
        
      >
<Marker
      icon={{
        path:
        "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
        fillColor: "red",
        fillOpacity: 0.6,
        scale: 1.5,
        strokeWeight: 0,
        rotation: 0
         
        
      }}
      position={{lat: 45.51186959025085, lng: -1.1255196722814045 }}
       
    />
        <></>
      </GoogleMap>
      </Container>
  ) : <></>
}

export default React.memo(MyComponent)



