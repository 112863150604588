import '../../App.css';
import { useTranslation } from 'react-i18next';
import MyMapComponent from '../MyMapComponent';
import Container from 'react-bootstrap/Container';







export default function Enviroment() {
    const { t } = useTranslation();

   




    return (
        <><Container>
            <h1>{t('LOCATION')}</h1>
           
            <MyMapComponent />
           <br></br><br></br>
           <b> {t('ADRESS')}:</b>  23 Rue du Cardinal Donnet, 33780 Soulac-sur-Mer, {t('FRANCE')}  <br></br><br></br>
                <b>{t('LOCATION1')} </b><br></br><br></br>
                <b>{t('LOCATION2')} </b><br></br><br></br>
                {t('LOCATION3')} <br></br><br></br>
                {t('LOCATION4')} <br></br><br></br>

                <b>{t('LOCATION5')} </b> <br></br><br></br>
                {t('LOCATION6')} <br></br><br></br>
                <a href="http://www.gironde.fr ">www.gironde.fr </a><br></br><br></br>
                <a href="http://www.bernezac.com ">www.bernezac.com </a><br></br><br></br>
                <b>{t('LOCATION7')} </b> <br></br><br></br>
                {t('LOCATION8')} <br></br><br></br>

         
            
        </Container></>
    )
}
