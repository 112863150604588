import '../../App.css';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel';


import img1 from '../../images/aboutus/Aboutus1_420.png';
import img2 from '../../images/aboutus/Aboutus1_980.png';
import img3 from '../../images/aboutus/Aboutus1_1680.png';
import Container from 'react-bootstrap/Container';

export default function Aboutus() {
    const { t } = useTranslation();

    let srcSet = img1 + ' 420w, '
        + img2 + ' 980w, '
        + img3 + ' 1680w';

    


    return (
        <><Container>
            <h1>{t('ABOUTUS')}</h1>
            <p><b>Belinda, Jérôme {t('AND')} Lexi</b> <br></br><br></br></p>

                {t('ABOUTUS1')} <br></br><br></br>
                {t('ABOUTUS2')}<br></br><br></br>
                
                <Container>


                
                            <img
                                className="d-block w-100"
                                src={img3}
                                sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                                srcSet={srcSet}
                                alt="The hosts" />
                      
                </Container>


                         

                           


        </Container></>
    )
}
