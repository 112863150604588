import React from 'react';
import Header from './components/Header';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Booking from './components/pages/Booking';
import Room from './components/pages/room/Room';
import CardsRoom from './components/CardsRooms';
import Aboutus from './components/pages/Aboutus';
import Region from './components/pages/Region';
import OurHouse from './components/pages/OurHouse';
import Enviroment from './components/pages/Enviroment';
import Faciltity from './components/pages/Facility';
import Terms from './components/pages/Terms';
import Footer from './components/Footer';
import { Outlet } from 'react-router-dom';
import PageNotFound from './components/pages/PageNotFound';
import './scss/custom.scss';
import Gallery from './components/pages/Gallery';
import Contact from './components/pages/Contact';
import Breakfast from './components/pages/Breakfast';

function App() {
return( 

<Router>
  <Header />
  <Routes>
    <Route excact path='/' element={<Home />} />
    <Route excact path='booking' element={<Booking />} />
    <Route excact path='room1' element={<Room roomId='room1' id='room1'/>} />
    <Route excact path='room2' element={<Room roomId='room2' id='room2'/>} />
    <Route excact path='room3' element={<Room roomId='room3' id='room3'/>} />
    <Route excact path='room4' element={<Room roomId='room4' id='room4'/>} />
    <Route excact path='rooms' element={<CardsRoom />} />
    <Route excact path='aboutus' element={<Aboutus />} />
    <Route excact path='region' element={<Region />} />
    <Route excact path='ourhouse' element={<OurHouse />} />
    <Route excact path='enviroment' element={<Enviroment />} />
    <Route excact path='facility' element={<Faciltity />} />
    <Route excact path='terms' element={<Terms />} />
    <Route excact path='breakfast' element={<Breakfast />} />
    <Route excact path='gallery' element={<Gallery />} />
    <Route excact path='contact' element={<Contact />} />
    <Route path="*" element={<PageNotFound />} />
   </Routes>
  <Outlet />
  <Footer />
  </Router>
)
 }
export default App;
    
