import React from 'react';
import '../../App.css';

import HomeVideoPart from "../HomeVideo";
import CardsRoom from '../CardsRooms'


function Home() {
  return (
    <>
      <HomeVideoPart />
     
      <CardsRoom/>
    </>
  );
}

export default Home;