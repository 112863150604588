import React, { useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import { useTranslation } from 'react-i18next';
import { roomMap } from './RoomHelper';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShower, faWind } from '@fortawesome/free-solid-svg-icons'
import { faBed } from '@fortawesome/free-solid-svg-icons'
import { faMugHot } from '@fortawesome/free-solid-svg-icons'
import { faShirt } from '@fortawesome/free-solid-svg-icons'
import { faWifi } from '@fortawesome/free-solid-svg-icons'
import { faCouch } from '@fortawesome/free-solid-svg-icons'
import { faToilet } from '@fortawesome/free-solid-svg-icons'
import { faBanSmoking } from '@fortawesome/free-solid-svg-icons'




export default function Room(props) {
  let roomId = props.roomId;

  const { t } = useTranslation();

  let initialized = false;

  let roomData = roomMap.get(roomId);
  let roomName = roomData.roomName;
  let roomDescription = t(roomData.roomDescription);
  let roomBed = roomData.roomBed;
  let smoobuDataVerification = roomData.smoobuDataVerification;
  let smoobuUrl = "https://login.smoobu.com/en/cockpit/widget/single-calendar/" + roomData.smoobuCode;
  let smoobuId = roomData.smoobuId;
  let roomSymbolBath = roomData.roomSymbolBath;
  let bathtext = t(roomData.roomDescBath);
  let couchtext = t(roomData.roomDescCouch);
  const initializeBookingScript = () => {
    const script = document.createElement("script");
    script.src = "https://login.smoobu.com/js/Apartment/CalendarWidget.js";
    script.async = true;
    document.body.appendChild(script);
  }
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      initializeBookingScript();
    }
  }, []);
  return (
    <>
      <Container fluid="md">
        <Row>
          <h1>{roomName}</h1><br></br>
          <p>
            {roomDescription}<br></br>
            <table>
              <tr>
                <td>
                  <FontAwesomeIcon icon={faBed} /> {roomBed} </td>
                <td>&nbsp;</td>
                <td><FontAwesomeIcon icon={faShower} /> <FontAwesomeIcon icon={faToilet} />  {t('SHOWER')}</td>
              </tr>
              <tr>
                <td><FontAwesomeIcon icon={faBanSmoking} />  {t('SMOKEING')}</td>
                <td></td>
                <td><FontAwesomeIcon icon={faWifi} />  {t('WIFI')} </td>

              </tr>
              <tr>
                <td><FontAwesomeIcon icon={faMugHot} /> {t('CAFE')} </td>
                <td></td>
                <td><FontAwesomeIcon icon={faCouch} /> {couchtext} </td>
              </tr>
              <tr>
                <td><FontAwesomeIcon icon={faShirt} /> {t('DRESSING')}</td>
                <td></td>
                <td><FontAwesomeIcon icon={faWind} /> {t('HAIRDRYER')}</td>
              </tr>
              <tr>
                <td>{roomSymbolBath} {bathtext}</td>
                <td></td>
                <td></td>
              </tr>
            </table>





          </p>
        </Row>
        <Row>


          <Carousel>
            {(() => {
              let items = [];
              for (let i = 0; i < Object.keys(roomData.carouselImages).length / 3; i++) {
                let imageId = i + 1;
                let imageName = roomData.roomImagesName + '_' + imageId + '_1680.png';
                let srcSet = roomData.carouselImages[roomData.roomImagesName + '_' + imageId + '_420.png'] + ' 420w, '
                  + roomData.carouselImages[roomData.roomImagesName + '_' + imageId + '_980.png'] + ' 980w, '
                  + roomData.carouselImages[roomData.roomImagesName + '_' + imageId + '_1680.png'] + ' 1680w';
                items.push(
                  <Carousel.Item key={"room-img-" + i} interval={2000}>
                    <img
                      className="d-block w-100"
                      src={roomData.carouselImages[imageName]}
                      sizes="(max-width: 420px) 90vw, (max-width:1100px) 70vw, 40vw"
                      srcSet={srcSet}
                      alt="breathtaking room" />
                  </Carousel.Item>

                );
              }
              return items;
            })()}
          </Carousel>
        </Row>
        <Row>
          <h1><br></br>{t('CALENDAR')} - {roomName}<br></br></h1>

          <div style={{ display: 'flex', justifyContent: 'center' }} id={smoobuId} className="calendarWidget">
            <div className="calendarContent" data-load-calendar-url={smoobuUrl} data-verification={smoobuDataVerification} data-baseurl="https://login.smoobu.com" data-disable-css="false" ></div>
            <script type="text/javascript" src="https://login.smoobu.com/js/Apartment/CalendarWidget.js"></script>
          </div>
        </Row>
      </Container>
    </>
  )
}